<template>
  <div class="container">
    <h1>{{ $t('notifications.title') }}</h1>
    <p v-if="notifications.length === 0">There are no notifications.</p>
    <table v-if="notifications.length > 0">
      <thead>
        <tr>
          <th>{{ $t('notifications.when') }}</th>
          <th>{{ $t('notifications.subject') }}</th>
          <th>{{ $t('notifications.message') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="notification in notifications" :key="notification.notificationId">
          <td>{{ formatDate(new Date(notification.when)) }}</td>
          <td>{{ notification.subject }}</td>
          <td>{{ notification.content }}</td>
        </tr>
      </tbody>
    </table>

    <h2>{{ $t('notifications.existingPreOrders') }}</h2>
    <table v-if="preOrders.length > 0">
      <tr>
        <th>{{ $t('notifications.preOrderTable.animal') }}</th>
        <th>{{ $t('notifications.preOrderTable.quantity') }}</th>
        <th>{{ $t('notifications.preOrderTable.name') }}</th>
        <th>{{ $t('notifications.preOrderTable.price') }}</th>
      </tr>
      <tr v-for="preOrder in preOrders" :key="preOrder.meatPieceId">
        <td>{{ preOrder.animalExternalId }} ({{ preOrder.animalType }} / {{ preOrder.animalBreed }})</td>
        <td>{{ preOrder.itemQuantity }}</td>
        <td>{{ preOrder.itemName }}</td>
        <td>{{ formatCurrency(preOrder.itemPriceInCents / 100) }}</td>
      </tr>
    </table>
    <p v-else>{{ $t('notifications.noPreOrders') }}</p>
  </div>
</template>

<script>
import { axiosInstance } from '@/library'

export default {
  name: "Notifications",
  components: {},

  data: () => ({
    notifications: [],
    preOrders: []
  }),

  beforeMount() {
    this.loadNotifications(this.$route.params.customerId)
    this.loadPreOrders(this.$route.params.customerId)
  },

  methods: {
    formatDate(date) {
      return new Intl.DateTimeFormat(navigator.language, { dateStyle: 'short', timeStyle: 'long' }).format(date)
    },

    formatCurrency(value) {
      return new Intl.NumberFormat(navigator.language, { style: 'currency', currency: 'EUR' }).format(value)
    },

    loadNotifications(customerId) {
      axiosInstance.get(`${window.VUE_APP_GRANJA}/api/notifications/${customerId}`)
        .then((response) => {
          this.notifications = response.data
        })
    },

    loadPreOrders(customerId) {
      axiosInstance.get(`${window.VUE_APP_GRANJA}/api/sales/pre-orders/${customerId}`)
        .then((response) => {
          this.preOrders = response.data
        })
    }
  }
}
</script>

<style>
TABLE {
  border-collapse: separate;
  border-spacing: 30px 0;
}

TH {
  text-align: left;
}
</style>
